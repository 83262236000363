<template>
  <div class="sket">
    <div class="box">
      <vue-esign
        ref="esign"
        :widht="800"
        :height="500"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        :bgColor.sync="bgColor"
      />
    </div>
    <div class="btnlist">
      <div @click="handleReset" class="btn">清空</div>
      <div @click="handleGenerate" class="btn1">提交</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineWidth: 3,
      lineColor: '#000000',
      bgColor: '',
      resultImg: '',
      isCrop: false,
      list:''
    }
  },
  mounted() {
    this.goHetong();
  },
  methods: {
    handleReset() {
      this.$refs.esign.reset()
    },
     async goHetong() {
      let id = window.localStorage.getItem("id");
      let data = id
      const res = await this.$ajaxRequest('get', 'saveMsg', '', data)
      console.log(res.data)
      this.list = res.data
    },
    async uploadSig(basr) {
      let data = {
        baseStr:basr,
        docxPath:this.list.docxPath,
        orderId:this.list.orderId
      }
      const res = await this.$ajaxRequest('post', 'uploadSig', data)
      console.log(res.data)
      if(res.code == 200){
        this.$message({
          message: res.msg,
          type: "success"
        });
         this.$router.replace('/myOrder')
      }else{
         this.$message({
          message: res.msg,
          type: "error"
        });
      }
      // this.list = res.data
    },
    handleGenerate() {
      this.$refs.esign.generate().then(res => {
        this.resultImg = res
        this.uploadSig(res)
      }).catch(err => {
        // ElMessage.error('请签字后提交');
        this
          .$confirm("请签字后提交", "签字", {
            showCancelButton: false,
            showClose: false,
            confirmButtonText: "确定",
            type: "warning"
          })
        // alert(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  border: 1px solid #333333;
  text-align: center;
}
.btnlist {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .btn {
    width: 102px;
    height: 34px;
    // background: linear-gradient(-17deg, #ed2e32 1%, #f8a927 100%);
    background-image: linear-gradient(176deg, #406bff 0%, #5f2eff 100%),
      linear-gradient(#f36470, #f36470);
    border-radius: 17px;
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn1 {
    width: 102px;
    height: 34px;
    // background: linear-gradient(-17deg, #ed2e32 1%, #f8a927 100%);
    background: linear-gradient(90deg, #ffba13 0%, #fb283a 100%);
    border-radius: 17px;
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
}
</style>
